import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import BrandsData from './data/brands.json'

const BrandList = dynamic(() => import('../components/Brand'))

interface Props {
  title: string
}

const BrandContainer: FC<Props> = ({ title }) => <BrandList title={title} brands={BrandsData} />

export default BrandContainer
